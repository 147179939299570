<template>
  <div class="pagination flex fa-cent fj-tween">
    <div v-show="!$store.getters.galleryLoading" class="pagination-container flex fa-cent fj-tween w-100" >
        <!-- <router-link :to="{name: 'gallery-paged', params: {pageNum: 1}}">
          <li class="page-number arrow">&#8676;</li>
        </router-link> -->
        <router-link :to="{ name: 'gallery-paged', params: {pageNum: toPageNbr } }"
        class="flex" v-if="parseInt($route.params.pageNum) > 1">
          <!-- <p class="page-number arrow --prev flex"></p> -->
          <span :class="{'--desactivated': parseInt($route.params.pageNum) == 1}" class="flex fj-start fa-cent col-gap-4 results">
            <i class="fa-solid fa-chevron-left"></i>
            <p>Previous</p>
          </span>
        </router-link>
        <span v-else-if="parseInt($route.params.pageNum) <= 1" :class="{'--desactivated': parseInt($route.params.pageNum) == 1}" class="flex fj-start fa-cent col-gap-4 results">
          <i class="fa-solid fa-chevron-left"></i>
          <p>Previous</p>
        </span>

        <p class="page-number --current-page fs-4" style="border-radius: 4px; text-align: center;">
          Page {{$route.params.pageNum}}
        </p>
        
        <router-link :to="{ name: 'gallery-paged', params: {pageNum: parseInt($route.params.pageNum) + 1} }" class="flex"
        v-if="$store.getters.gallerySprites && $store.getters.gallerySprites.length == $store.getters.rpp">
          <span class="flex fj-end fa-cent col-gap-4 results">
            <p>Next</p>
            <i class="fa-solid fa-chevron-right"></i>
          </span>
        </router-link>
        <span class="flex fj-end fa-cent col-gap-4 results"
        :class="{'--desactivated': $store.getters.gallerySprites && $store.getters.gallerySprites.length < $store.getters.rpp}"
        v-else-if="$store.getters.gallerySprites && $store.getters.gallerySprites.length < $store.getters.rpp">
          <p>Next</p>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
    </div>
  </div>
</template>

<script>
import svgIconPlus from '@/components/svgIconPlus'

export default {
  name: "pagination",
  components: {
    svgIconPlus,
  },
  props: {
    pageNum : {
      type: Number,
      required: true
    },
    list: {
      type: Array,
      required: false
    }
  },
  data(){
    return{
      step: 6,
    }
  },
  methods: {
  },
  computed: {
    // numberOfPages(){
    //   return Math.ceil(this.list.length/this.rpp)
    // }
    toPageNbr(){
      // if(parseInt(this.$route.params.pageNum === 1)){
      //   return 1
      // }
      // else{
        return (parseInt(this.$route.params.pageNum) - 1)
      // }
    }
  },
}
</script>

<style>
.pagination{
  position: relative;
  
  width: 100%;
  padding: calc(var(--space-unit)*8) 40px;
}
.pagination-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin: 0 auto;
}
.--current-page{
  /* box-shadow: 0 0 0 1px var(--over-spread) inset; */
  color: var(--color-o-spread);
}
.--current-page:hover{
  /* box-shadow: 0 0 0 1px var(--over-spread) inset; */
  color: var(--color-o-spread);
}
.page-number{
  /* font-size: 0.9rem; */
  color: var(--color-o-spread);
  list-style-type: none;
  border: none;
  display: inline;
  line-height: 1;
  padding: 8px 24px;
  transition: color var(--med-trans), box-shadow var(--fast-trans);
}
.page-number:hover{
  color: var(--color-o-spread);
}
.page-number.arrow{
  width: 40px;
  height: 64px;
}
.page-number.arrow.--prev{
  clip-path: polygon( 100% 0%, 0% 50%, 100% 100%);
}
.page-number.arrow.--next{
  clip-path: polygon( 0% 0%, 100% 50%, 0% 100%);
}

.pagination-options-info{
  display: inline;
}
.pagination-options,
.pagination-form{
  display: flex;
}
.pagination input{
  display: inline;
}
.results{
  /* width: calc(100vw/3); */
  width: 100%;
  height: calc(var(--size-unit)*16);
  color: var(--color-o-spread);
  box-shadow: 0 0 0 1px currentColor;
  padding: calc(var(--space-unit)*5);
  border-radius: calc(var(--size-unit)*1);
  transition: box-shadow .32s ease, color .32s ease;
}
.results:hover{
  color: var(--color-accent);
  box-shadow: 0 0 0 2px currentColor;
  cursor: pointer;
}
.--desactivated, .--desactivated:hover{
  color: hsla(var(--o-spread), 0.2);
  box-shadow: 0 0 0 1px currentColor;
  cursor: unset;
}
</style>
