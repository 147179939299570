<template>
  <header>
    <div class="menu-bar">
      <div class="container">
        <router-link to="/">
          <div class="logo-block">
            <svg-icon class="logo-symbol" name="spritebox-logo"/>
            <h1 class="logo-title">spritebooth</h1>
          </div>
        </router-link>
        <nav id="nav" class="flex fa-cent fj-cent" style="width: 100%">
          <ul class="nav --left flex fa-cent fj-cent">
            <li class="nav-list rel h-100 flex fj-cent fa-cent">
              <router-link class="nav-router-link" :to="{ path:'/gallery'}">Gallery</router-link>
            </li>
            <li class="nav-list rel h-100 flex fj-cent fa-cent">
              <router-link class="nav-router-link" :to="{ path: '/docs'}">Docs</router-link>
            </li>
          </ul>
          <ul class="nav --right flex">
            <li class="nav-list rel h-100 flex fj-cent fa-cent pointer">
              <i class="fa-solid fa-bell"></i>
            </li>
            <li class="nav-list rel h-100 flex fj-cent fa-cent pointer">
              <span @click="togglePanel()">
                <i class="fa-solid fa-gear"></i>
              </span>
              <div class="settings-panel flex fd-col fj-start"
              :class="{'--opened': panelSettingsOpened}">
                <div class="flex fj-tween">
                  <p class="">ui</p>
                  <input type="checkbox" @input=""/>
                </div>
              </div>
            </li>
            <li class="nav-list rel h-100 flex fj-cent fa-cent">
              <router-link :to="{path: '/editor'}"
              class="no-border"
              style="padding: 8px 12px; box-shadow: 0 0 0 1px currentColor;
              border-radius: 4px;">
                <!-- <i class="fa-solid fa-plus"></i> -->
                new
              </router-link>
            </li>
            <li class="nav-list rel h-100 flex fj-cent fa-cent">
              <router-link class="nav-list-link flex fa-cent fj-cent" :to="{path: '/profile'}">
              {{ $store.getters.AuthUser ? 'Profile' : 'Log In' }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <modal/> -->

    </div>
  </header>
</template>

<script>
import SvgIcon from "@/components/svgIcon.vue"
import store from "@/store/store.js"
// import modal from "@/components/modal.vue"

export default {
  name: 'menuBar',
  components: {
    // modal,
  },
  data(){
    return{
      panelSettingsOpened: false,
    }
  },
  computed: {
    sessionUser(){
      return this.$store.state.authUser
    },
  },
  methods: {
    togglePanel(){
      this.panelSettingsOpened = ! this.panelSettingsOpened
    }
  }
}
</script>

<style>
header{
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--nav-h);
  color: var(--color-o-spread);
  background-color: hsla(var(--spread), 0.8);
  transition: background-color var(--slow-trans), color var(--slow-trans);
  border-bottom: 1px var(--color-o-spread) dotted;
  backdrop-filter: blur(6px);
}
.logo-block{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}
.logo-title{
  font-size: 1.6rem;
  font-weight: 600;
  font-family: var(--font-fam-2);
  margin-left: 4px;
  letter-spacing: 0;
}
p, a, input{
  font-family: var(--font-fam-2);
}
.nav.--left {
  width: -webkit-fill-available;
}
#nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#nav li{
  list-style-type: none;
}
#nav li:not(:last-child) {
  margin-right: calc(var(--space-unit)*3);
}
.menu-bar a{
  color: currentColor;
}
.nav-list{
  height: var(--nav-h);
  display: flex;
  width: max-content;
}

#nav .router-link-active:before,
#nav .router-link-exact-active::before,
#nav .router-link-exact-active:hover::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 4px solid var(--color-o-spread);
  height: var(--nav-h);
}
.logo-block svg {
  height: 40px;
  width: 40px;
  margin-right: 8px;
}
.menu-bar .container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 40px;
}
.no-border::before{
  border: none !important;
}
@media screen and (max-width: 768px){
  .logo-title{
    display: none;
  }
}
.settings-panel{
  background: hsla(var(--spread), 0.8);
  position: absolute;
  width: 200px;
  height: 200px;
  top: calc(var(--nav-h) + var(--space-unit)*2);
  right: 0;
  box-shadow: 0 0 0 1px var(--color-o-spread);
  padding: calc(var(--space-unit)*1) calc(var(--space-unit)*2);
  border-radius: calc(var(--size-unit)*1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(var(--space-unit)*3));
  transition: opacity .24s ease, transform .24s ease;
}
.settings-panel.--opened{
  opacity: 1;
  transform: translateY(0);
  pointer-events: unset;
}
</style>
