<template lang="html">
  <div class="hero">
    <div class="hero-container">
      <h1 class="hero-title">Pixel art in the browser</h1>
      <h2>Draw, Animate &amp; Publish your artworks</h2>
      <p>
      Spritebooth is an online pixel art editor &amp; a web render engine.<br>
      Craft your illustrations &amp; animations and run them in the browser
      </p>
      <button type="button" class="hero-cta" name="button">Get Started</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "hero",
}
</script>

<style lang="css">
.hero{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 400px;
  color: var(--panel-hover);
  background-color: hsla(0,0%,0%, 0.75);
}
.hero h2{
  font-weight: 500;
  color: var(--panel-color);
}
.hero p{
  font-weight: 400;
  color: #ddd;
}
.hero-container {
  margin: 0 40px;
}
.hero-cta{
  color: var(--panel-color);
  margin: 24px 0;
}
.hero-title {
  color: var(--panel-hover);
  font-weight: bold;
}
.hero-cta:hover {
  color: var(--panel-hover);
}
</style>
