<template lang="html">
  <div class="modal" :class="{'modalOn':modalOpened}">
    <div class="modal-overlay">
        <form class="modal-form">
          <div class="form-block">
            <input type="number" name="" class="modal-input" @change="SET_TEMPLATE_SIZE">
            <input type="number" name="" class="modal-input" @change="SET_TEMPLATE_SIZE">
          </div>
          <div class="form-block">
            <button type="button" class="modal-button" @click="SET_TEMPLATE_SIZE">create</button>
            <button type="button" class="modal-button" @click="toggleModal">cancel</button>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import store from "@/store/store"

export default {
  name: "modal",
  data(){
    return{
      modalS: {

      },
      modalOpened: false,
    }
  },
  computed: {
    modalSize(){
      return store.getters.getNewTemplateSize
    }
  },
  methods: {
    // SET_TEMPLATE_SIZE(){
    //   store.commit('setNewTemplateSize', this.modalSize)
    //   // console.log("Set template method called.")
    // },
    toggleModal(){
      if (this.modalOpened){
        !this.modalOpened
      }else{
        this.modalOpened
      }
    }
  },
  // beforeCreate(){
  //   this.modalS = this.modalSize
  // }
}
</script>

<style>
.modalOn{
  opacity: 1;
  pointer-events: all;
}
.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 100vh; */
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-overlay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-80);
  color: var(--over-bg);
}
.modal-form{
  position: relative;
  padding: 16px;
  width: 400px;
  border-radius: 4px;
  height: auto;
  background-color: var(--color-bg-80);
  box-shadow: 0 0 0 1px var(--over-bg) inset;
}
.form-block:nth-of-type(2){
  margin-top: 24px;
}
input.modal-input{
  box-shadow: 0 0 0 1px solid var(--over-bg);
  height: 80px;
  text-align: center;
}
input.modal-input:nth-of-type(2){
  margin-top: 16px;
}
.modal-button{
  width: 50%;
}

</style>
