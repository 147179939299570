<template>
  <div class="gallery">
    <Sprites :pageNum="parseInt($route.params.pageNum)"/>
    <pagination :pageNum="parseInt($route.params.pageNum)"/>
    <footer-bar v-if="!$store.getters.galleryLoading"/>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue"

export default {
  name: 'Gallery',
  components: {
    pagination,
    Sprites: () => ({
      component: import('@/components/Sprites'),
    }),
    
  },
  data(){
    return {

    }
  },
  method: {

  },
  beforeMount(){
    // document.getElementById('app').scrollIntoView()
  },
  mounted(){
  },
  beforeRouteUpdate (to,from, next) {
  //  react to route changes...
  //  don't forget to call next()
  //  document.getElementById('app').scrollIntoView()
    // window.scrollTo(0,0)
    next()
  },
}
</script>

<style lang="css" >
.gallery{
  position: absolute;
  top: var(--nav-h);
  width: 100%;
  height: calc(100% - var(--footer-h) - var(--nav-h) );
  /* margin-bottom: calc(var(--space-unit)*16); */
}
</style>
