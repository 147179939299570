<template>
  <div class="footer-bar flex fa-cent fj-cent">
    <container class="padh-5">
      <p>© copyright spritebooth 2023. All rights reserved</p>
    </container>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue"
import container from "@/components/Container.vue"

export default {
    name: "footer-bar",
    components: {
      pagination,
      container,
    }
  }
</script>

<style>
.footer-bar{
  position: relative;
  bottom: 0;
  height: var(--footer-h);
  width: 100%;
  border-top: 1px var(--color-o-spread) dotted;
  backdrop-filter: blur(4px);
  background-color: hsla(var(--spread), 0.8);
}
</style>

