<template>
  <div class="__container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
}
</script>

<style scoped>

.__container {
  --adjust: 1;
}
@media screen and (max-width: 1080px) {
  .__container {
    --adjust: 1;
  }
}
@media screen and (max-width: 768px) {
  .__container {
  --adjust: 0.666666;
  }
}
@media screen and (max-width: 480px) {
  .__container {
    --adjust: 0.333333;
  }
}

.__container {
  --container-padh: calc( var(--space-unit) * (var(--padh, 0) * var(--adjust)) );
  --container-padv: calc( var(--space-unit) * (var(--padv, 0) * var(--adjust)) );

  display: flex;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding-left: var(--container-padh);
  padding-right: var(--container-padh);
  padding-top: var(--container-padv);
  padding-bottom: var(--container-padv);
}
</style>
