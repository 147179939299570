<template>
  <div class="main home">
    <hero/>
  </div>

</template>

<script>
// @ is an alias to /src
// import SpriteEditor from '@/components/SpriteEditor.vue'
// import menuBar from "@/components/menuBar.vue"
import modal from "@/components/modal.vue"
import hero from "@/components/hero.vue"

export default {
  name: 'home',
  components: {
    modal,
    hero
  },
}
</script>
<style>

.promise{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  text-align: center;
}
</style>
