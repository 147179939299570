import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store/store.js'


import Gallery from '@/views/Gallery.vue'
import {ROOT, HTML} from '@/constants.js'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { 
      backgroundColor: false,
      authRequired: true,
    },
  },
  {
    name: 'gallery',
    path: '/gallery',
    redirect: '/gallery/1',
    component: () => import('./views/Gallery.vue'),
    meta: { 
      backgroundColor: false,
      authRequired: true,
    },
  },
  {
    name: 'gallery-paged',
    path: '/gallery/:pageNum',
    component: () => import('./views/Gallery.vue'),
    meta: { 
      backgroundColor: false,
      authRequired: true,
    },
  },
  {
    path: '/editor/:unique_key',
    name: 'editor',
    component: () => import('./components/SpriteEditor.vue'),
    meta: {
      authRequired: true,
      backgroundColor: true,
    },
  },
  {
    path: '/editor',
    name: "new",
    redirect: '/editor/blank',
    component: () => import('./components/SpriteEditor.vue'),
    meta: {
      modalInfo: true,
      backgroundColor: false,
      authRequired: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./views/Profile.vue'),
    meta: {
      authRequired: true,
      backgroundColor: false,
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('./views/About.vue'),
    meta: { 
      backgroundColor: false,
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/Login.vue'),
    meta: { backgroundColor: false },
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('./views/Documentation.vue'),
    meta: { 
      backgroundColor: false,
      authRequired: true,
    },
  },
  {
    path: '/*',
    name: '404',
    component: () => import('./views/page404.vue'),
    meta: { backgroundColor: false },
  },
]


const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ savedPosition })
        }, 500)
      })
    } else if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash })
        }, 500)
      })
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  },
})

/* ------------------------------------- ROUTE GUARD ---------------------------------------- */

router.afterEach((to, from, next) => {
  if (to.meta.backgroundColor == false){
    // if( settings in localhost ){ load user preferences}
    if(store.state.preferences.user.theme === 'light') {
      HTML.classList.remove('--theme-dark')
      HTML.classList.add('--theme-light')
      ROOT.style.setProperty('--spread', 'var(--lightest)') // only change the spead
    }
    else if (store.state.preferences.user.theme === 'dark') {
      HTML.classList.remove('--theme-light')
      HTML.classList.add('--theme-dark')
      ROOT.style.setProperty('--spread', 'var(--darkest)') // only change the spread
    }
  }
})

router.beforeEach(async (to, from, next) => {

  if (to.matched.some((route) => route.meta.authRequired)) {
    // if no user in store AND no user in localStorage
    if (!store.getters.AuthUser && !("supabase.auth.token" in localStorage)) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      })
    }
    ///-- else if no user in store AND user in localStorage
    
    else if (!store.getters.AuthUser && "supabase.auth.token" in localStorage) {
      const token = localStorage.getItem("supabase.auth.token")
      const accessToken = JSON.parse(token).currentSession.access_token
      await store.dispatch("checkLocalStorageSession", accessToken)
      await store.dispatch('getUserSprites', store.state.authUser.id)
      
      next()
    }
    
    // if user
    else if (store.getters.AuthUser) {
      next()
    }
  }
  else {
    if("supabase.auth.token" in localStorage){  
      const token = localStorage.getItem("supabase.auth.token")
      
      const accessToken = JSON.parse(token).currentSession.access_token
      await store.dispatch("checkLocalStorageSession", accessToken)
      await store.dispatch('getUserSprites', store.state.authUser.id)
    }
      
    next()
  }

})

export default router
