<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg"
  :style="'fill:' + fillColor + '; stroke-width:' + strokeWidth + '; stroke:'+ strokeColor + '; stroke-linecap:' + strokeLinecap +'; stroke-linejoin:' + strokeLinejoin + '; height:' + iconHeight + '; width:' + iconWidth + ';' ">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
export default {
  name: 'svgIconF',
  props: {
    folder: {
      type: String,
      default: "icons"
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    strokeColor: {
      type: String,
    },
    strokeWidth: {
      type: String,
    },
    fillColor: {
      type: String,
    },
    strokeLinecap: {
      type: String,
      default: "round"
    },
    strokeLinejoin: {
      type: String,
      default: "round",
    },
    iconHeight: {
      type: String,
      default: "calc(var(--size-unit)*4)"
    },
    iconWidth: {
      type: String,
      default: "calc(var(--size-unit)*4)"
    }
  },

  computed: {
    iconPath() {
      let icon = require(`@/assets/${this.folder}/${this.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }
      return icon.url;
    },
    className() {
      return 'svg-icon svg-icon--' + this.name;
    }
  }
};
</script>

<style>
  .svg-icon {
    cursor: pointer;
  }
  .svg-icon.fill{
    fill: currentColor;
  }
  .svg-icon.no-fill{
    fill: none;
  }
  .svg-icon.stroke{
    stroke: currentColor;
  }
  .svg-icon.round{
    stroke-linecap:round;
    stroke-linejoin:round;
  }
</style>
