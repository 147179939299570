<template>
  <div id="app">
    <menuBar/>
    <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app',
  data(){
    return {
    }
  },
  async beforeMount(){
  },
  async mounted(){
  }
}
</script>

<style>
@import url('./assets/css/diffrax.css');
@import url('./assets/css/fonts.css');
@import url('./assets/css/metrix.css');
@import url('./assets/css/vue-toast.css');
@import url('./assets/css/class-utils.css');

html,body,div,span,applet,object,iframe,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,
form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,
video{margin:0;padding:0;border:0;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}
*, *:before, *:after {box-sizing: border-box;}
div{image-rendering: pixelated;}
body {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  overflow-x: hidden;
  image-rendering: pixelated;
  transition: background-color var(--slow-trans), color var(--slow-trans);
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  display: none;
}
.fade-enter-active, .fade-leave-active {
  transition-property: opacity, transform;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

.fade-enter-active {
  transition-delay: 0s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  transform: translateX(24px);
}
#app {
  position: relative;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.h-100{height: 100%}
a{
  color: var(--over-spread);
  text-decoration: none;
  cursor: pointer;
}
.main {
  position: relative;
  top: var(--nav-h);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.title {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--color-o-spread);
}
.title-2{
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--color-o-spread);
  margin-bottom: 8px;
  margin-top: 24px;
}
.title-2:before {
  content: '•';
  margin-right: 8px;
}
b {
  font-weight: 550;
}
.underlined{
  border-bottom: 1px solid var(--color-o-spread);
}
label {
  margin-left: 8px;
  user-select:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
}
button {
  font-size: 0.65rem;
  appearance: none;
  border-style: none;
  padding: 4px 16px;
  margin: 8px 0 0;
  border-radius: 4px;
  height: 32px;
  background: transparent;
  color: var(--color-o-spread);
  box-shadow: 0 0 0 1px currentColor;
  transition: color var(--fast-trans), box-shadow var(--fast-trans), background-color var(--fast-trans), transform var(--fast-trans);
}
button:focus {
  outline: none;
}
button:hover:active{
  transform: scale(0.96);
}
button:hover {
  cursor: pointer;
  color: var(--color-o-spread);
  box-shadow: 0 0 0 1px var(--color-o-spread);
  /* transform: scale(1.04); */
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="password"]{
  border-style: none;
  height: 32px;
  padding: 4px 4px;
  width: 100%;
  font-size: 0.65rem;
  background-color: transparent;
  color: var(--color-o-spread);
  box-shadow: 0 0 0 1px currentColor inset;
  transition: box-shadow var(--fast-trans), background-color var(--fast-trans), color var(--fast-trans), border var(--fast-trans), transform var(--fast-trans);
  border-radius: 4px;
  outline: none;
}
input[type="text"]::placeholder,
input[type="url"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder{
  color: currentColor;
}
input[type="text"]:hover,
input[type="url"]:hover,
input[type="email"]:hover,
input[type="number"]:hover,
input[type="password"]:hover{
  /* color: var(--over-spread); */
  box-shadow: 0 0 0 1px currentColor inset;
}
input:active:hover,
input:not[type="range"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus{
  /* box-shadow: 0 0 0 1px currentColor inset; */
  transform: scale(1.05);
}
input[type="text"]:active,
input[type="url"]:active,
input[type="email"]:active,
input[type="number"]:active,
input[type="password"]:active{
  transform: scale(1.05);
  box-shadow: 0 0 0 1px var(--over-spread) inset;
}
input[type="range"] {
  width: 100%;
  margin-top: 16px;
}
input[type="range"]:focus,
input[type="range"]:hover {
  cursor: pointer;
}

input[type="radio"].toggle {
  display: none;
}

input[type="radio"].toggle + label{
  background: none;
  color: var(--over-spread);
  padding: 8px 8px;
  font-size: 0.8rem;
  box-shadow: 0 0 0 0 var(--over-spread);
  border-radius: 4px;
}
input[type="radio"].toggle + label:hover{
  color: var(--over-spread);
  cursor: pointer;
}
input[type="radio"].toggle:checked + label {
  box-shadow: 0 0 0 1px var(--over-spread) inset;
  color: var(--over-spread);
}
input[type="radio"].toggle label {
}
input:-internal-autofill-selected {
  background-color: transparent !important;
  color: var(--color-o-spread) !important;
}

label {
  width: inherit;
}
p {
  margin: 6px 0 6px;
  line-height: 1.333;
}
</style>
