// src/store/store.js
import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import blank from "@/new_sprite.js"

Vue.use(Vuex)

import { supabase } from '@/supabase.js'
import { set } from 'lodash'
const redirectUrl = {
  prod: 'https://www.spritebooth.com/profile',
  dev: 'http://localhost:3000/profile'
}

export default new Vuex.Store({
  state: {
    dbSpritesList: [],
    blank,
    newTemplate: blank,
    spriteIndex: 0,
    newTemplateSize: {
      width: 16,
      height: 24
    },
    userSprites: null,
    authUser: null,
    AuthSession: null,
    AuthLoading: false,
    galleryLoading: false,
    gallerySprites: null,
    registerMode: false,
    notifications: [
    ],
    // result per page
    rpp: 24,
    preferences: {
      user: {
        theme: 'dark',
      }
    },
  },
  getters: {
    selectedSprite(state) {
      return state.dbSpritesList[state.spriteIndex]
    },
    rpp(state) {
      return state.rpp
    },
    AuthUser(state) {
      return state.authUser
    },
    AuthLoading(state) {
      return state.AuthLoading
    },
    UserSprites(state){
      return state.userSprites
    },
    galleryLoading(state){
      return state.galleryLoading 
    },
    gallerySprites(state){
      return state.gallerySprites
    }
  },
  mutations: {
    setGalleryLoading(state, payload){
      if(typeof payload == 'boolean'){
        state.galleryLoading = payload
      }
    },
    updateUserSpriteUrlsAllowed(state, payload){
      let index = state.userSprites.findIndex(x => x.id == payload.old.id )
      console.log(index)
      state.userSprites[index] = payload.new
    },
    setGallerySprites(state, sprites){
      state.gallerySprites = sprites
    },
    setUserSprites(state, sprites){
      state.userSprites = sprites
    },
    deleteNotif(state) {
      state.notifications.pop()
    },
    set_User(state, user) {
      state.authUser = user
    },
    setRegisterModeOn(state) {
      state.registerMode = true
    },
    setRegisterModeOff(state) {
      state.registerMode = false
    },
    SetAuthUser(state, user) {
      state.authUser = user
    },
    SetAuthSession(state, session) {
      state.AuthSession = session
    },
    SetAuthLoadingState(state, loadingState) {
      state.AuthLoading = loadingState
    },
  },
  actions: {
    async getUserSprites({commit}, userId){
       const { data, error } = await supabase
      .from('sprites')
      .select()
      .eq('author', userId)
      .order('last_modified', { ascending: false })
      commit('setUserSprites', data)
    },
    async LogInWithMagicLink({ commit }, emailArg) {
      try {
        commit("SetAuthLoadingState", true)
        let { user, error } = await supabase.auth.signIn({
          email: emailArg
        },
          {
            redirectTo: window.location.origin + '/profile'
          }
        )
        // if (error) throw error
        this._vm.$toast(
          `Thank you ! Check your mailbox and click on login link please.`,
          { timeout: 8000,icon: 'fas fa-circle-user',}
        )
      }
      catch (error) {
        this._vm.$toast(
          `${error.error_description || error.message}`,
          { timeout: 8000,icon: 'fas fa-circle-user',}
        )
        commit('SetAuthUser', null)
      }
      finally {
        commit("SetAuthLoadingState", false)
      }
    },
    async checkLocalStorageSession({ commit }, accessToken) {
      try {
        commit("SetAuthLoadingState", true)
        let { user, error } = await supabase.auth.api.getUser(accessToken)
        if (user) {
          // console.log(user)
          commit("SetAuthUser", user)
          this._vm.$toast(`You are logged in with ${user.email}.`, {
            timeout: 8000,
            icon: "fas fa-circle-user",
          })
        }
        else if (error) {
          this._vm.$toast(`${error.error_description || error.message}`, {
            timeout: 8000,
            icon: "fas fa-circle-user",
          })
          commit("SetAuthUser", null)
        }
      } catch (error) {
        this._vm.$toast(`${error.error_description || error.message}`, {
          timeout: 8000,
          icon: "fas fa-circle-user",
        })
        commit("SetAuthUser", null)
      } finally {
        commit("SetAuthLoadingState", false)
      }
    },
  },
})

