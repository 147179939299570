import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import './registerServiceWorker'
import store from "@/store/store.js"
import menuBar from "@/components/menuBar.vue"
import SvgIcon from "@/components/svgIcon.vue"
import FooterBar from "@/components/FooterBar"
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { inject } from '@vercel/analytics';
inject();


Vue.config.productionTip = false
Vue.config.debug = true

Vue.use(router)

Vue.component('footer-bar', FooterBar)
Vue.component('svg-icon', SvgIcon)
Vue.component('menuBar', menuBar)

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.use(Toast, {
  // You can set your default options here
  position: POSITION.TOP_RIGHT,
  transitionDuration: 440,
  closeButton: false,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
